
const typeScale = options => {

    const type = {
        baseFontSize: 18,
        baseLineHeightRatio: 1.75,
        headingScaleRatio: 1.275,
        // NOTE: Currently only "px" is supported.
        // cssLengthFormat: 'px',
        ...(options || {})
    }

    type.rhythm = type.baseFontSize * type.baseLineHeightRatio

    new Array('h6FontSize', 'h5FontSize', 'h4FontSize').map(key => {
        ! type[key] && (type[key] = type.baseFontSize)
    })
    for (let i = 4; i > 0; i--) {
        type[`h${i}FontSize`] = type[`h${i + 1}FontSize`] * type.headingScaleRatio
    }

    type.headingSizes = [
        type.h1FontSize,
        type.h2FontSize,
        type.h3FontSize,
        type.h4FontSize,
        type.h5FontSize,
        type.h6FontSize,
    ]

    return type;
}

export default typeScale
