import typeScale from './../scripts/type-scale'

const typography = typeScale()

// Theme Specs (https://theme-ui.com/theme-spec/)

const theme = {
    // Configuration Flags (https://theme-ui.com/theming#configuration-flags)
    //...
    // Z-Index
    zIndices: {
        navbar: 9998,
        mobileNav: 9999,
    },
    // Breakpoints (https://theme-ui.com/theming#breakpoints). Must be ordered mobile first.
    breakpoints: ['600px', '900px', '1200px'],
    // Margin & Padding
    space: [0, 8, 16, 24, 32, 40, 48, 56, 64, 72],
    // Height & Width
    sizes: {
        tightest: 700,
        tighter: 800,
        tight: 900,
        base: 1000,
        wide: 1100,
        wider: 1200,
        widest: 1300,
    },
    // Typography (https://theme-ui.com/theming#typography)
    fonts: {
        // helvetica: `"Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif`,
        mono: `Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace`,
        system: `system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
        body: 'Inter, sans-serif', //`Rubik`,
        heading: 'Inter, sans-serif', //`Ubuntu`,
        button: 'Inter, sans-serif', //`Ubuntu`,
    },
    fontSizes: [
        // NOTE: Font sizes correlate (in order of apperance) to 0, h1 to h6, base, small, tiny.
        0, ...typography.headingSizes, typography.baseFontSize, `15px`, `13px`,
    ],
    fontWeights: {
        body: 400,
        heading: 700,
        bold: 700,
    },
    lineHeights: {
        tightest: 1.125,
        tighter: 1.25,
        tight: 1.375,
        base: 1.5,
        wide: 1.625,
        wider: 1.75,
        widest: 1.875,
    },
    letterSpacings: {
        body: 'normal',
        // caps: '.2em',
    },
    radii: [0, 1, 2, 3, 4, 5],
    colors: {
        background: '#fff', // is: body
        text: `#6e6e6e`,
        heading: '#3f3f3f',
        primary: '#4b7bec', //'#0850FE', // buttons & links
        primaryDark: '#3867d6',
        secondary: '#45aaf2', // can be used for hover states
        secondaryDark: '#2d98da',
        accent: '#eb3b5a', // contrast color for emphasizing UI
        highlight: '#fed330', // for highlighting text
        muted: '#f0f2f4', // decorative gray or subdued color
        shades: {
            0: `rgba(0,0,0,.05)`,
            1: `rgba(0,0,0,.1)`,
            2: `rgba(0,0,0,.2)`,
            3: `rgba(0,0,0,.3)`,
            4: `rgba(0,0,0,.4)`,
            5: `rgba(0,0,0,.5)`,
            6: `rgba(0,0,0,.6)`,
            7: `rgba(0,0,0,.7)`,
            8: `rgba(0,0,0,.8)`,
            9: `rgba(0,0,0,.9)`,
        },
        modes: {
            dark: {
                text: '#fff',
                background: '#000',
                primary: '#0cf',
            },
        },
    },
}

// Aliases

theme.breakpoints.sm = theme.breakpoints[0]
theme.breakpoints.md = theme.breakpoints[1]
theme.breakpoints.lg = theme.breakpoints[2]

theme.fontSizes.body = theme.fontSizes[7]
theme.fontSizes.small = theme.fontSizes[8]
theme.fontSizes.tiny = theme.fontSizes[9]

theme.lineHeights.body = typography.baseLineHeightRatio
theme.lineHeights.heading = theme.lineHeights.tighter

// UI Component Settings

theme.sizes.container = '1400px'

// Variants (https://theme-ui.com/theme-spec/#variants)

theme.text = {
    caps: {
        textTransform: 'uppercase',
        letterSpacing: '.075rem',
        // fontWeight: 'bold',
    },
    heading: {
        fontFamily: 'heading',
        fontWeight: 'heading',
        lineHeight: 'heading',
        color: 'heading',
    },
    paragraph: {
        lineHeight: 'base',
        mb: typography.baseFontSize * typography.baseLineHeightRatio + 'px',
    },
}

theme.row = {
    secondary: {
        bg: 'muted',
        boxShadow: `inset 0 1px rgba(0,0,0,.03), inset 0 -1px rgba(0,0,0,.03)`,
    },
}

// MDX Styles (https://theme-ui.com/theming#styles)
theme.styles = {
    // root: <body> (https://theme-ui.com/theming#body-styles)
    root: {
        fontFamily: 'body',
        fontWeight: 'body',
        fontSize: 'body',
        lineHeight: 'body',
        backgroundColor: 'body',
        color: 'text',
    },
    h1: {variant: 'text.heading',},
    h2: {variant: 'text.heading',},
    h3: {variant: 'text.heading',},
    h4: {variant: 'text.heading',},
    h5: {variant: 'text.heading',},
    h6: {variant: 'text.heading',},
    // a: {
    //     color: '#eee',
    // },
}

export {
    theme as default,
    typography
}
